<template>
  <div>
    <h2 class="th-title">Verifikasi</h2>
    <a-card>
      <a-modal v-model:visible="modalVisible" title="Yakin ingin menolak data ini?" @ok="handleOk" ok-text="Ya, Tolak">
        <a-form layout="vertical">
          <a-form-item
            label="Keterangan Penolakan"
            name="message"
          >
            <a-input placeholder="Masukkan alasan verifikasi ditolak" v-model:value="rejecObject.message" />
          </a-form-item>
        </a-form>
      </a-modal>
      <div class="d-flex justify-content-between mb-3">
        <a-input
          style="width: 230px"
          v-model:value="search"
          @change="handleSearch"
          placeholder="Cari berdasarkan nama / nik"
        >
          <template #prefix>
            <span>
              <search-outlined style="color: rgba(0, 0, 0, 0.25)" />
            </span>
          </template>
        </a-input>
        <a-select class="ml-2" style="width: 200px;" :value="status" @change="handleSelect">
            <a-select-option value="all">
            Semua Status
          </a-select-option>
          <a-select-option value="success">
            Sudah Terifikasi
          </a-select-option>
          <a-select-option value="on_progress">
            Perlu di Verifikasi
          </a-select-option>
          <a-select-option value="failed">
            Gagal verifikasi
          </a-select-option>
        </a-select>
      </div>
      <div>
        <a-table :class="$style.table" :columns="columns" :data-source="dataTable" bordered :loading="loadingTable" :pagination="globalPagination" @change="onTableChange" >
          <template #bankAccount="{ text }" >
            <span :style="{ whiteSpace: 'break-spaces'}">
              <a-tag
                v-for="item in text"
                :key="item._id"
              >
                {{ item.fullString }}
              </a-tag>
            </span>
          </template>
         <template #fullName="{ text, record }">
            <router-link class="text-primary kit__utils__link" :to="{ name: 'Detail Akun', params: { id: record.userId } }">{{ text }}</router-link>
          </template>
          <template class="d-flex justify-content-center" #selfie="{ text }">
            <a-image 
              :style="{ aspectRatio: 1}"
              :width="120"
              :height="120"
              :src="`${URL}/${text}`"
              alt="selfie"
              class="img-thumbnail"
            />
          </template>
          <template class="d-flex justify-content-center" #idCardFilepath="{ text }">
            <a-image 
              :style="{ aspectRatio: 1}"
              :width="120"
              :height="120"
              :src="`${URL}/${text}`"
              alt="idCardFilepath"
              class="img-thumbnail"
            />
          </template>
          <template #action="{record }">
            <span>
              <a-popover v-if="record.status == 'on_progress'">
                <template #content class="kit__utils__link font-size-16 text-success" >Setujui Akun</template>
                <a-popconfirm
                  title="Yakin ingin menyetujui data ini?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="setujuiAkun(record.key)"
                >
                  <button type="button" class="btn btn-outline-success">
                    <i class="fe fe-thumbs-up" aria-hidden="true"></i>
                  </button>
                </a-popconfirm>
              </a-popover>
              <button v-if="record.status == 'on_progress'" type="button" class="btn btn-outline-danger ml-1" @click="showModal(record._id)">
                <i class="fe fe-thumbs-down" aria-hidden="true"></i>
              </button>
            </span>
          </template>
          <template #status="{text}">
             <span class="d-flex justify-content-center">
                 <a-tag class="align-self-start" v-if="text == 'success'" color="success">
                <template #icon>
                  <check-circle-outlined />
                </template>
                Sudah Terifikasi
              </a-tag>
              <a-tag class="align-self-start" v-else-if="text == 'failed'" color="error">
                <template #icon>
                  <close-circle-outlined />
                </template>
                Gagal verifikasi
              </a-tag>
              <a-tag class="align-self-start" v-else color="warning">
                <template #icon>
                  <exclamation-circle-outlined />
                </template>
                Perlu di Verifikasi
              </a-tag>
              </span>
          </template>
        </a-table>
      </div>
    </a-card>
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRaw } from 'vue';
import { useStore } from 'vuex';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { Modal, notification } from 'ant-design-vue';
import { ExclamationCircleOutlined, CheckOutlined, CloseOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons-vue';
import { createVNode } from 'vue';
import { useForm } from '@ant-design-vue/use';
const URL = process.env.VUE_APP_BACKEND_URL;

const columns = [
  {
    title: 'No',
    dataIndex: 'rowNumber',
    key: 'rowNumber',
    slots: { customRender: 'rowNumber' },
    align: 'center',
    fixed: 'left',
  },
  {
    title: 'Nama Lengkap',
    dataIndex: 'fullName',
    key: 'fullName',
    width: 150,
    slots: { customRender: 'fullName' },
    fixed: 'left',
  },
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
    width: 150,
    slots: { customRender: 'username' },
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    width: 150,
    slots: { customRender: 'email' },
  },
  {
    title: 'Nomor HP',
    dataIndex: 'phoneNumber',
    width: 150,
    key: 'phoneNumber',
    slots: { customRender: 'phoneNumber' },
    align: 'center',
  },
  {
    title: 'Nomor KTP',
    dataIndex: 'idCardNumber',
    width: 150,
    key: 'idCardNumber',
    slots: { customRender: 'idCardNumber' },
    align: 'center',
  },
  {
    title: 'Akun Bank',
    dataIndex: 'bankAccount',
    width: 150,
    key: 'bankAccount',
    slots: { customRender: 'bankAccount' },
    align: 'center',
  },
  // {
  //   title: 'Akun Bank (1st)',
  //   dataIndex: 'userBankAccount',
  //   width: 150,
  //   key: 'userBankAccount',
  //   slots: { customRender: 'userBankAccount' },
  //   align: 'center',
  // },
  {
    title: 'Selfie',
    dataIndex: 'selfie',
    width: 150,
    key: 'selfie',
    slots: { customRender: 'selfie' },
    align: 'center',
  },
  {
    title: 'Foto KTP',
    dataIndex: 'idCardFilepath',
    width: 150,
    key: 'idCardFilepath',
    slots: { customRender: 'idCardFilepath' },
    align: 'center',
  },
  {
    title: 'Creator Plan',
    width: 100,
    dataIndex: 'pickedPlan',
    key: 'pickedPlan',
    slots: { customRender: 'pickedPlan' },
    align: 'center',
  },
  {
    title: 'Tanggal Pengajuan',
    width: 100,
    dataIndex: 'tanggalPengajuan',
    key: 'tanggalPengajuan',
    slots: { customRender: 'tanggalPengajuan' },
    align: 'center',
  },
  {
    title: 'Tanggal Verifikasi',
    width: 100,
    dataIndex: 'tanggalVerifikasi',
    key: 'tanggalVerifikasi',
    slots: { customRender: 'tanggalVerifikasi' },
    align: 'center',
  },
  {
    title: 'Pesan',
    width: 100,
    dataIndex: 'message',
    key: 'message',
    slots: { customRender: 'message' },
    align: 'center',
  },
  {
    title: 'Status',
    width: 100,
    dataIndex: 'status',
    key: 'status',
    slots: { customRender: 'status' },
    align: 'center',
    fixed: 'right',
  },
  {
    title: 'Aksi',
    width: 150,
    key: 'action',
    slots: { customRender: 'action' },
    align: 'center',
    fixed: 'right',
  },
];

export default {
  components: {
    SearchOutlined,
    // PlusOutlined,
    // CheckOutlined,
    // CloseOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined,
  },
  setup() {
    const store = useStore()
    let data = ref([])
    let dataTable = ref([])
    let globalPagination = ref({ current: 1, pageSize: 10 })
    let search = ref('')
    let sortBy = ref('createdAt')
    let order = ref('desc')
    let loadingTable = ref(false)
    let modalVisible = ref(false)
    let status = ref('all')
    let rejecObject = ref({
      id: null,
      message: null,
    })

    onMounted(() => {
      fetchCreatorVerificationHistory()
    })

    const fetchCreatorVerificationHistory = async (search) => {
      loadingTable.value = true
      const apiFetchACVH = await store.dispatch('creator/FETCH_ALL_CREATOR_VERIFICATION_HISTORY', { search, status: status.value, page: globalPagination.value.current, limit: globalPagination.value.pageSize, sortBy: sortBy.value, order: order.value })
      
      // console.log('fetchCreatorVerificationHistory apiFetchACVH =>', apiFetchACVH)

      if (apiFetchACVH) {
        const { data, totalAllData } = apiFetchACVH;
        globalPagination.value = { ...globalPagination.value, total: totalAllData }
  
        dataTable.value = data.map(el => {
          return {
            key: el._id,
            ...el,
          }
        })
      }

      loadingTable.value = false
    }

    const onTableChange = async(pagination, filters, sorter) => {
      // console.log('pagination, filters, sorter', pagination, filters, sorter)
      globalPagination.value.current = pagination.current
      if (sorter) {
        if (sorter.columnKey == 'key') sortBy.value = '_id';
        else sortBy.value = sorter.columnKey;
        order.value = sorter.order == 'ascend' ? 'asc' : 'desc';
      }
      fetchCreatorVerificationHistory()
    }

    const showModal = (id) => {
      modalVisible.value = true
      rejecObject.value.id = id
    }

    const handleOk = async() => {
      await store.dispatch('creator/EDIT_CREATOR_VERIFICATION_HISTORY', { id: rejecObject.value.id, status: 'failed', message: rejecObject.value.message })
      fetchCreatorVerificationHistory()
      modalVisible.value = false
    }

    const handleSearch = (e) => {
      // console.log('handleSearch', e.target.value);
      search.value = e.target.value
      fetchCreatorVerificationHistory(e.target.value)
    }

    const handleSelect = (val) => {
      // console.log('handleSelect val', val)
      status.value = val
      fetchCreatorVerificationHistory()
    }

    const setujuiAkun = async(id) => {
      await store.dispatch('creator/EDIT_CREATOR_VERIFICATION_HISTORY', { id, status: 'success', message: 'accepted' })
      fetchCreatorVerificationHistory()
    }

    return {
      data,
      dataTable,
      columns,
      loadingTable,
      search,
      modalVisible,
      showModal,
      handleOk,
      status,
      handleSelect,
      onTableChange,
      handleSearch,
      globalPagination,
      sortBy,
      order,
      URL,
      setujuiAkun,
      rejecObject,
    }
  },
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
